<template>
  <div>
    <a-card title="入库标签打印">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="6" style="max-width: 320px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="产品编号, 名称, 批次编号" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="8" :xl="10" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">导入</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">导出</a-button>
            <a-button icon="barcode" @click="handlePrint(items)">批量打印</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="openFormModal(form)"
            >新增入库标签</a-button
          >
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button icon="edit" size="small" @click="openFormModal(item)">编辑</a-button>
              <a-button icon="barcode" size="small" @click="handlePrint([item])">打印</a-button>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal v-model="visible" :form="targetItem" @create="create" @update="update" />
    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
    <a-modal v-model="exportLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导出中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import {
  stockInLabelList,
  stockInLabelDestroy,
  stockInLabelExport,
  stockInLabelTemplate,
  stockInLabelImport,
} from "@/api/label";
import { exportExcel } from "@/utils/excel";
import printLabel from "./printLabel";
import columns from "./columns.js";

export default {
  components: {
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", is_active: undefined, page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],

      visible: false,
      targetItem: {},
      form: {},
      importLoading: false,
      exportLoading: false,
    };
  },
  computed: {
    stockInLabelSize() {
      return this.$store.state.user.barConfig.stockInLabelSize;
    },
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      stockInLabelList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      this.items.splice(0, 0, item);
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    destroy(id) {
      stockInLabelDestroy({ id }).then(() => {
        this.items.splice(
          this.items.findIndex((item) => item.id == id),
          1
        );
        this.$message.success("删除成功");
      });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    openFormModal(item) {
      this.targetItem = { ...item };
      this.visible = true;
    },
    exportExcel() {
      this.exportLoading = true;
      stockInLabelExport(this.searchForm)
        .then((data) => {
          exportExcel(data, "入库标签列表");
        })
        .catch((error) => {
          this.$message.error(error.response.data.error);
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    downloadTemplate() {
      stockInLabelTemplate()
        .then((data) => {
          exportExcel(data, "入库标签导入模板");
        })
        .catch((error) => {
          this.$message.error(error.response.data.error);
        });
    },
    importExcel(item) {
      const data = new FormData();
      data.append("file", item.file);

      this.importLoading = true;
      stockInLabelImport(data)
        .then(() => {
          this.$message.success("导入成功");
          this.list();
        })
        .catch((error) => {
          this.$message.error(error.response.data.detail);
        })
        .finally(() => {
          this.importLoading = false;
        });
    },
    //     labelPrint(item) {
    //       if (!this.stockInLabelWidth || !this.stockInLabelLength) {
    //         this.$message.warning("请先在标签配置中设置标签参数!");
    //         return false;
    //       }

    //       const qrBase64 = jrQrcode.getQrBase64(
    //         JSON.stringify({
    //           batch_number: item.batch_number,
    //           stock_in_month: item.stock_in_month,
    //           material_item: { number: item.material_item.number },
    //         }),
    //         { width: 160, height: 160 }
    //       );
    //       const htmlText = `
    // <html>
    //   <body style="margin: 0">
    //     <div style="width: ${this.stockInLabelLength}mm; height: ${this.stockInLabelWidth}mm">
    //       <table cellspacing="0" style="font-size: 16px; width: 100%; height: 100%; text-align: center">
    //         <tr>
    //           <td rowspan="6">
    //             <img src="${qrBase64}" />
    //           </td>
    //         </tr>
    //         <tr>
    //           <td>产品编号:</td>
    //           <td colspan="3">${item.material_item.number ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>产品名称:</td>
    //           <td colspan="3">${item.material_item.name ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>批次编号:</td>
    //           <td colspan="3">${item.batch_number ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>入库时间:</td>
    //           <td colspan="3">${item.stock_in_month ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>规格:</td>
    //           <td>${item.material_item.spec ?? ""}</td>
    //           <td>单位:</td>
    //           <td>${item.material_item.unit ?? ""}</td>
    //         </tr>
    //       </table>
    //     </div>
    //   </body>
    // </html>
    // `;

    //       const LODOP = getLodop();
    //       LODOP.PRINT_INIT("Stock In Label");
    //       LODOP.SET_PRINT_PAGESIZE(1, `${this.stockInLabelLength}mm`, `${this.stockInLabelWidth}mm`);
    //       LODOP.NewPage();

    //       LODOP.ADD_PRINT_HTM(0, 0, `${this.stockInLabelLength}mm`, `${this.stockInLabelWidth}mm`, htmlText);
    //       LODOP.PREVIEW();
    //     },
    //     batchPrint() {
    //       if (!this.items.length) {
    //         this.$message.warning("暂无打印的数据!");
    //         return false;
    //       }
    //       if (!this.stockInLabelWidth || !this.stockInLabelLength) {
    //         this.$message.warning("请先在标签配置中设置标签参数!");
    //         return false;
    //       }
    //       const LODOP = getLodop();
    //       LODOP.PRINT_INIT("Stock In Label");
    //       LODOP.SET_PRINT_PAGESIZE(1, `${this.stockInLabelLength}mm`, `${this.stockInLabelWidth}mm`);

    //       this.items.map((item) => {
    //         const qrBase64 = jrQrcode.getQrBase64(
    //           JSON.stringify({
    //             batch_number: item.batch_number,
    //             stock_in_month: item.stock_in_month,
    //             material_item: { number: item.material_item.number },
    //           }),
    //           { width: 160, height: 160 }
    //         );
    //         const htmlText = `
    // <html>
    //   <body style="margin: 0">
    //     <div style="width: ${this.stockInLabelLength}mm; height: ${this.stockInLabelWidth}mm">
    //       <table cellspacing="0" style="font-size: 16px; width: 100%; height: 100%; text-align: center">
    //         <tr>
    //           <td rowspan="6">
    //             <img src="${qrBase64}" />
    //           </td>
    //         </tr>
    //         <tr>
    //           <td>产品编号:</td>
    //           <td colspan="3">${item.material_item.number ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>产品名称:</td>
    //           <td colspan="3">${item.material_item.name ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>批次编号:</td>
    //           <td colspan="3">${item.batch_number ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>入库时间:</td>
    //           <td colspan="3">${item.stock_in_month ?? ""}</td>
    //         </tr>
    //         <tr>
    //           <td>规格:</td>
    //           <td>${item.material_item.spec ?? ""}</td>
    //           <td>单位:</td>
    //           <td>${item.material_item.unit ?? ""}</td>
    //         </tr>
    //       </table>
    //     </div>
    //   </body>
    // </html>
    // `;

    //         LODOP.NewPage();
    //         LODOP.ADD_PRINT_HTM(0, 0, `${this.stockInLabelLength}mm`, `${this.stockInLabelWidth}mm`, htmlText);
    //       });
    //       LODOP.PREVIEW();
    //     },
    handlePrint(items) {
      if (!this.stockInLabelSize) {
        this.$message.warning("请先在标签配置中设置标签参数!");
        return false;
      }

      printLabel(items, this.stockInLabelSize);
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
