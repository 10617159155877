import { getLodop } from "@/assets/LodopFuncs";
import jrQrcode from "jr-qrcode";
import moment from "moment";

export default function printLabel(items, labelSize) {
  const LODOP = getLodop();
  LODOP.PRINT_INIT("Location Label");
  LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
  LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

  if (labelSize === "length_95_width_43") {
    LODOP.SET_PRINT_PAGESIZE(1, "95mm", "43mm");
  }

  for (const item of items) {
    print_length_95_width_43_label(LODOP, item);
  }

  LODOP.PREVIEW();
}

function print_length_95_width_43_label(LODOP, item) {
  const qrBase64 = jrQrcode.getQrBase64(
    JSON.stringify({
      batch_number: item.batch_number,
      material_item: { number: item.material_item.number },
    }),
    { width: 160, height: 160 }
  );

  const htmlText = `
<div style="padding: 1mm;">
  <table border="1" cellspacing="0" style="font-size: 14px; width: 100%; height: 100%; text-align: center">
    <tr>
      <td rowspan="6" style="width: 35mm">
        <img src="${qrBase64}" />
      </td>
    </tr>
    <tr>
      <td style="width: 10mm">编号</td>
      <td colspan="3">${item.material_item.number ?? ""}</td>
    </tr>
    <tr>
      <td style="width: 10mm">名称</td>
      <td colspan="3">${item.material_item.name ?? ""}</td>
    </tr>
    <tr>
      <td style="width: 10mm">规格</td>
      <td colspan="3">${item.material_item.spec ?? ""}</td>
    </tr>
    <tr>
      <td style="width: 10mm">
        <div>批次</div>
        <div>编号</div>
      </td>
      <td>${item.batch_number ?? ""}</td>
      <td style="width: 6mm">
        <div>单</div>
        <div>位</div>
      </td>
      <td>${item.material_item.unit ?? ""}</td>
    </tr>
    <tr>
      <td style="width: 10mm">
        <div>入库</div>
        <div>月份</div>
      </td>
      <td>${item.stock_in_month ? moment(item.stock_in_month).format("YYYY-MM") : ""}</td>
      <td style="width: 6mm">
        <div>专</div>
        <div>业</div>
      </td>
      <td>${item.category_items.map((item) => item.name).join(", ")}</td>
    </tr>
  </table>
</div>`;

  LODOP.NewPage();
  LODOP.ADD_PRINT_HTM("0%", "0%", "100%", "100%", htmlText);
}
