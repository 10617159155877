import moment from "moment";

export default [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: "产品编号",
    dataIndex: "number",
    customRender: (value, item) => item.material_item.number,
  },
  {
    title: "产品名称",
    dataIndex: "name",
    customRender: (value, item) => item.material_item.name,
  },
  {
    title: "产品规格",
    dataIndex: "remark",
    customRender: (value, item) => item.material_item.spec,
  },
  {
    title: "产品分类",
    dataIndex: "category",
    customRender: (value, item) => {
      return item.category_items.map((item) => item.name).join(", ");
    },
  },
  {
    title: "客户",
    dataIndex: "client_name",
    customRender: (value, item) => {
      return item.client_item.name;
    },
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    sorter: true,
  },
  {
    title: "入库月份",
    dataIndex: "stock_in_month",
    customRender: (value) => {
      return moment(value).format("YYYY-MM");
    },
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "156px",
  },
];
