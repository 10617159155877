import request from "@/utils/request";

export function stockInLabelList(params) {
  return request({ url: `/stock_in_labels/`, method: "get", params });
}

export function stockInLabelCreate(data) {
  return request({ url: `/stock_in_labels/`, method: "post", data });
}

export function stockInLabelUpdate(data) {
  return request({ url: `/stock_in_labels/${data.id}/`, method: "put", data });
}

export function stockInLabelDestroy(data) {
  return request({ url: `/stock_in_labels/${data.id}/`, method: "delete", data });
}

export function stockInLabelTemplate(params) {
  return request({
    url: "/stock_in_labels/import_template/",
    responseType: "blob",
    method: "get",
    params,
  });
}

export function stockInLabelImport(data) {
  return request({
    url: "/stock_in_labels/import_data/",
    method: "post",
    data,
  });
}

export function stockInLabelExport(params) {
  return request({
    url: "/stock_in_labels/export/",
    responseType: "blob",
    method: "get",
    params,
  });
}
